
@font-face {
  font-family: 'AUTHENTIC Sans';
  src: url('fonts/authentic-sans/AUTHENTICSans-60.woff2') format('woff2'),
  url('fonts/authentic-sans/AUTHENTICSans-60.woff') format('woff');
  font-style: normal;
  font-weight: 100 300;
}

@font-face {
  font-family: 'AUTHENTIC Sans';
  src: url('fonts/authentic-sans/AUTHENTICSans-90.woff2') format('woff2'),
  url('fonts/authentic-sans/AUTHENTICSans-90.woff') format('woff');
  font-style: normal;
  font-weight: 300 600;
}

@font-face {
  font-family: 'AUTHENTIC Sans';
  src: url('fonts/authentic-sans/AUTHENTICSans-130.woff2') format('woff2'),
  url('fonts/authentic-sans/AUTHENTICSans-130.woff') format('woff');
  font-weight: 700 800;
  font-style: normal;
}

@font-face {
  font-family: 'AUTHENTIC Sans';
  src: url('fonts/authentic-sans/AUTHENTICSans-150.woff2') format('woff2'),
  url('fonts/authentic-sans/AUTHENTICSans-150.woff') format('woff');
  font-weight: 900 1000;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'AUTHENTIC Sans', Arial, sans-serif;
}

* {
  user-select: none;
}

input, textarea {
  user-select: auto;
}
