.InformationFormSelect select option {
    font-size: 1.5rem;
}

@media (orientation: landscape) and (min-width: 1080px) {
    .InformationFormSelect select option {
        font-size: 1rem;
    }
}

#root .simple-keyboard.hg-theme-default {
    background-color: #fff;
}

#root .simple-keyboard.hg-theme-default .hg-button {
    height: 60px;
    background-color: #333;
    color: #fff;
    font-size: 23px;
}