html, body, #root {
    height: 100%;
    background: #fff;
}

section.main, section.titled-content {
    width: 100%;
    height: 100%;
    max-width: 1080px;
    margin: auto;
    background: #fff;
    position: relative;
}

@media (orientation: landscape) {
    section.titled-content:not([data-page="informations"]):not([data-page="ask"]):not([data-page="send"]) {
        max-width: max(100vh, 600px);
    }
}

.IdleTimer {
    position: fixed !important;
    width: auto !important;
    text-align: center !important;
}

body .margin-v {
    margin-top: 2vh;
    margin-bottom: 2vh;
}

body .margin-v-large {
    margin-top: 10vh;
    margin-bottom: 10vh;
}

.WithResponsivePadding {
    padding: 10px;
}

@media (orientation: portrait) {
    .WithResponsivePadding {
        padding: 2vh 2vw;
    }
    .ignoreResponsivePaddingOnPortrait {
        width: calc(101% + 4vw);
        margin: 0 -2vw;
    }
}

@media (orientation: landscape) and (min-height: 1080px) {
    .WithResponsivePadding {
        padding: 2.5vh 0;
    }
}

h1.big {
    font-size: 20vw;
}

.logo {
    width: 20%;
    max-width: 140px;
    cursor: pointer
}

@media (min-width: 1080px) {
    h1.big {
        font-size: 14rem;
    }
}

@media (orientation: landscape) {
    .page-title {
        position: absolute;
        top: 20px;
        margin-left: min(25%, 160px);
        padding: 0;
        display: flex;
        min-height: 70px;
    }
    .page-title h1 {
        font-size: min(2.6rem, max(1.8rem, 4.5vh));
        margin: auto;
    }
    .page-title br {
        content: ' '
    }
    .page-title br:after {
        content: ' '
    }
}

video, .video-react, video:focus, .video-react:focus {
    outline: none;
}

*:focus {
    outline: none;
}

.small-square {
    width: 14px;
    height: 14px;
    margin: 0 10px;
    vertical-align: middle;
    display: inline-block;
    background: #e7411d;
}

br-portrait {
    display: none;
}

@media (orientation: portrait) {
    br-portrait {
        display: block;
    }
}
