.video-react {
    overflow: hidden;
}

.video-react-readonly .video-react-big-play-button {
    display: none !important;
}

.blink {
    animation: blinker 1s step-start infinite;
}

.firstClickButton {
    padding-top: 50px;
    width: 12vw;
    margin: auto;
    font-size: 1vw;
    color: #ff2a00  ;
    font-weight: bold;
}

.loader-container {
    padding-top: 50px;
    width: 12vw;
    margin: auto;
    font-size: 11vw;
    color: #ff2a00  ;
    font-weight: bold;
}

.blink-loader {
    overflow: hidden;
    padding-left: 3vw;
    animation: blinker-loader 2.5s steps(4, start) infinite;
}

@keyframes blinker {
    50% { opacity: 0 }
}

@keyframes blinker-loader {
    from { width: 0 }
    to { width: 100% }
}
