.video-react .video-react-poster {
    background-color: transparent;
}

.video-finished .video-react-poster, .video-react-paused video {
    filter: grayscale(100%);
}

.video-react .video-react-big-play-button {
    height: 200px;
    width: 200px;
    margin-top: -100px !important;
    margin-left: -100px !important;
    border-radius: 50%;
    border: none;
    background-color: rgba(43, 51, 63, 0.4);
}

.video-react:hover .video-react-big-play-button, .video-react .video-react-big-play-button:focus {
    background-color: rgba(43, 51, 63, 0.3);
}

.video-react .video-react-big-play-button:before {
    font-size: 120px;
    line-height: 200px;
}

.video-finished .video-react .video-react-big-play-button:before {
    content: "";
    display: block;
    background-size: 150px 150px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 24 24"><path fill="white" d="M12 5V1L7 6l5 5V7c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6H4c0 4.42 3.58 8 8 8s8-3.58 8-8-3.58-8-8-8z"></path><path fill="none" d="M0 0h24v24H0V0z"></path></svg>');
}

@media (max-width: 800px) {
    .video-react .video-react-big-play-button {
        height: 100px;
        width: 100px;
        margin-top: -50px !important;
        margin-left: -50px !important;
    }

    .video-react .video-react-big-play-button:before {
        font-size: 60px;
        line-height: 100px;
    }

    .video-finished .video-react .video-react-big-play-button:before {
        background-size: 75px 75px;
    }
}

.video-react {
    background: none;
}

/* Delay the play button appearing in order to never show it if the autoplay feature works on this device */
.video-react .video-react-big-play-button {
    opacity: 0;
    animation: fadeout .45s;
    animation-delay: .45s;
    animation-fill-mode: forwards;
}
@keyframes fadeout {
    from { opacity: 0; }
    to { opacity: 1; }
}
